<script setup lang="ts">
import useSessionStore from '~/store/session'
import usePartnersStore from '~/store/partners'
import useNetworksStore from '~/store/networks'
import type { CommunityNetwork, Network } from '~/interfaces/network'
import useMenuStore from '~/store/menu'
import MenuUserSection from './MenuUserSection.vue'

const { user } = useSessionStore()
const partnerStore = usePartnersStore()
const menuStore = useMenuStore()

const route = useRoute()

const currentNetwork = computed<Network | CommunityNetwork | null>(
    () =>
        useNetworksStore().currentNetwork ||
        useNetworksStore().currentCommunityNetwork
)

const networksPageLink = computed(
    () => `/partners/${partnerStore.currentPartner?.id}/networks`
)

const isBig = ref(true as boolean)

const toggleMenuSize = () => {
    isBig.value = !isBig.value
}

const isCurrentRoute = (routeName: string) => routeName === route.name

const closeMobileMenu = () => {
    menuStore.toggleMobileMenuOpen()
}
</script>

<template>
    <ChargemapMenu
        v-if="user"
        :is-partner="true"
        :user
        logoName="LogoPartnersMenu"
        @closeMobileMenu="closeMobileMenu"
        @updateSize="toggleMenuSize"
    >
        <template #context-actions>
            <NuxtLink
                :to="networksPageLink"
                class="p-2 hover:bg-primary-600 border border-primary-500 rounded-lg justify-start items-center gap-2 inline-flex w-full"
                :class="[
                    isCurrentRoute('partners-idPartner-networks')
                        ? 'bg-primary-600'
                        : 'bg-primary-700'
                ]"
            >
                <div
                    class="p-2 bg-primary-500 rounded-[3px] flex-col justify-center items-center gap-2 inline-flex"
                    :class="[isBig ? 'w-10 h-10' : 'w-8 h-8']"
                >
                    <IconBuildingOffice
                        is-solid
                        class="relative text-primary-50"
                        :class="[isBig ? 'w-6 h-6' : 'w-4 h-4']"
                    />
                </div>
                <div
                    v-if="isBig"
                    class="flex-1 flex-col justify-start items-start inline-flex"
                >
                    <div
                        class="self-stretch text-primary-100 text-base font-semibold leading-normal whitespace-nowrap"
                    >
                        {{ currentNetwork?.name }}
                    </div>
                    <div
                        class="self-stretch text-primary-100 text-xs font-normal leading-[18px] whitespace-nowrap"
                    >
                        {{
                            currentNetwork?.rating
                                ? `${$n(currentNetwork?.rating)}/5 | `
                                : ''
                        }}{{ currentNetwork?.zonesCount }} stations
                    </div>
                </div>
                <IconArrowRight
                    v-if="isBig"
                    class="w-4 h-4 relative text-primary-50 mr-1"
                />
            </NuxtLink>
        </template>
        <template #default="slotProps">
            <MenuUserSection :isBig="slotProps.isBig" />
        </template>
    </ChargemapMenu>
</template>
